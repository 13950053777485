@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.carousel {
  overflow: hidden;
}

.carouselItem {
  flex: 0 0 100%;
  min-width: 0;
}

.carouselArrowStart {
  position: absolute;
  top: calc(50% - var(--spacing-2));
  left: 0;
  z-index: 1;

  @include media-query.breakpoint(md) {
    left: calc(var(--spacing-4) * -1);
  }
}

.carouselArrowEnd {
  position: absolute;
  top: calc(50% - var(--spacing-2));
  right: 0;
  z-index: 1;

  @include media-query.breakpoint(md) {
    right: calc(var(--spacing-4) * -1);
  }
}