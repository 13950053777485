@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';
@use '../../../styles/utils/map-deep-get.scss';
@use '../../../styles/utils/media.scss';

@keyframes overlay {
  from {
    opacity: 0;
  }
  to {
    to: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  background-color: var(--color-semantic-surface-scrim);
  opacity: 0.8;
  position: fixed;
  inset: 0;
  z-index: 999;

  @media (prefers-reduced-motion: no-preference) {
    animation: overlay 50ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}

.header {
  background-color: var(--color-semantic-background-default);
}

.modalBoxGrid {
  max-width: var(--max-width);
  margin: 0 auto;

  position: fixed;
  isolation: isolate;
  inset: 0;
  z-index: 999;
}

.fixedHeight {
  height: 100vh;
}

.content {
  flex-grow: 1;
  overflow-y: auto;

  &.borderTop {
    border-top: 1px solid var(--color-semantic-border-default);
  }
  &.borderBottom {
    border-bottom: 1px solid var(--color-semantic-border-default);
  }
}

.footer {
  background-color: var(--color-semantic-background-default);
}

.modalBox {
  background-color: var(--color-semantic-background-default);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  z-index: 2;
  overflow: hidden;
  position: absolute;

  &.disableInternalScroll {
    overflow-y: unset;
  }

  &:focus {
    outline: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}

@include media.uniqueMedia('small') {
  width: 80%;
  max-height: calc(100% - (var(--spacing-8) * 2));

  @include media-query.breakpoint(md) {
    width: 60%;
  }
  @include media-query.breakpoint(lg) {
    width: 50%;
  }

  @include media-query.breakpoint(xl) {
    max-height: calc(100% - (var(--spacing-9) * 2));
    width: 50%;
  }
}

@include media.uniqueMedia('large') {
  @include media-query.breakpoint(null, sm) {
    width: 100%;
    top: var(--spacing-8);
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }

  @include media-query.breakpoint(sm) {
    max-height: calc(100% - (var(--spacing-8) * 2));
    width: 80%;
  }

  @include media-query.breakpoint(lg) {
    width: 80%;
  }

  @include media-query.breakpoint(xl) {
    width: 80%;
    max-height: calc(100% - (var(--spacing-9) * 2));
  }
}

.modalBox-closeButtonContainer {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
