@use 'sass:map';
@use 'sass:list';

$breakpoints: (
  'sm': 480px,
  'md': 768px,
  'lg': 992px,
  'xl': 1280px,
);

$allBreakpointKeys: 'sm', 'md', 'lg', 'xl';
$allBreakpointValues: 480px, 768px, 992px, 1280px;

@mixin minWidthMedia($selector) {
  .#{$selector} {
    @content;
  }

  @each $breakpoint, $breakpointValue in $breakpoints {
    @media screen and (min-width: $breakpointValue) {
      .#{$breakpoint}\:#{$selector} {
        @content;
      }
    }
  }
}

@mixin uniqueMedia($selector) {
  .#{$selector} {
    @content;
  }

  @each $breakpoint in $allBreakpointKeys {
    $index: list.index(
      $list: $allBreakpointKeys,
      $value: $breakpoint,
    );

    $currentBreakpointValue: list.nth($allBreakpointValues, $index);

    @if $index == 1 {
      $nextBreakpointValues: list.nth($allBreakpointValues, 2);
      @media only screen and (max-width: calc($nextBreakpointValues - 1px)) {
        .#{$breakpoint}\:#{$selector} {
          @content;
        }
      }
    } @else if $index == list.length($allBreakpointKeys) {
      $previousBreakpointValue: list.nth($allBreakpointValues, $index - 1);
      $previousBreakpointKey: list.nth($allBreakpointKeys, $index - 1);

      @media only screen and (min-width: $previousBreakpointValue) and (max-width: calc($currentBreakpointValue - 1px)) {
        .#{$previousBreakpointKey}\:#{$selector} {
          @content;
        }
      }

      @media only screen and (min-width: calc($currentBreakpointValue)) {
        .#{$breakpoint}\:#{$selector} {
          @content;
        }
      }
    } @else if $index != 2 {
      $previousBreakpointValue: list.nth($allBreakpointValues, $index - 1);
      $previousBreakpointKey: list.nth($allBreakpointKeys, $index - 1);
      @media only screen and (min-width: $previousBreakpointValue) and (max-width: calc($currentBreakpointValue - 1px)) {
        .#{$previousBreakpointKey}\:#{$selector} {
          @content;
        }
      }
    }
  }
}
