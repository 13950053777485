@use 'sass:color';

@mixin direction-switch($direction, $color) {
  background: linear-gradient(to $direction,
      transparent 0%,
      $color 20%,
      $color 100%,
    );
}

.container {
  position: absolute;
  z-index: 1;
  height: 100%;
  flex: 1;
  width: var(--gradient-overlay-width, 44px);
}

.start {
  left: 0;
  @include direction-switch(left, var(--color-semantic-surface-default));
}

.end {
  right: 0;
  @include direction-switch(right, var(--color-semantic-surface-default));
}