@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss' as mq;

.ProductTileLink {
  text-decoration: none;
  color: inherit;
  border-radius: var(--border-radius-small);

  &:focus,
  &:focus-visible {
    display: block;
  }
}

.ModalTileLink {
  text-decoration: none;
  color: inherit;
  border-radius: var(--border-radius-small);
  display: block;

  &:focus,
  &:focus-visible {
    display: block;
  }
}

.DiscountBundleLink {
  position: relative;
  z-index: 2;

  &:hover {
    text-decoration: underline;
  }
}