.ModalContent {
  position: relative;
  width: 100%;
  max-width: 1024px;
  top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
  // small
  @media (min-width: 480px) {
    width: 80%;
  }

  // medium
  @media (min-width: 768px) {
    top: var(--spacing-9);
    margin-bottom: var(--spacing-9);
  }
}

$overlay-color: #615f5b;

.overlay {
  z-index: 999;
  background: rgba($overlay-color, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  padding-bottom: var(--spacing-8);
}

.closeButton {
  position: absolute;
  right: var(--spacing-4);
  top: var(--spacing-4);
}
