@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';
@use '~@kolonialno/kolibri-web/styles/utils/focus-ring';

.linkListButtonImage {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.noShrink > * {
  flex-shrink: 0;
}

.column {
  /* fix for inconsistent hack, as suggested from https://css-tricks.com/almanac/properties/b/break-inside/ */
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  display: inline-block;
  width: 100%;
  padding: var(--spacing-4) 0;

  border-bottom: 1px solid var(--color-semantic-border-default);

  @include media-query.breakpoint(md) {
    border-bottom: none;
    padding: var(--spacing-3) 0;
  }
}

.CloseButton {
  z-index: 3;
  position: fixed;
  right: var(--spacing-3);
  top: var(--spacing-6);
}
