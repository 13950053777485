.product-image {
  background-color: var(--color-semantic-surface-product-image-background);
}

.list-item {
  width: 64px;
}

.universal-product-row {
  width: 72px;
  height: 72px;
}

.recipe-list-item {
  width: 72px;
}

.row {
  width: 48px;
  padding: 2px;
}

.small-row {
  width: 40px;
  padding: 2px;
}

.tile {
  width: 128px;
}

.recipe-product-row {
  width: 64px;
}

.highlighted-tile {
  width: 144px;
}

.detail {
  width: 100%;
}
