.ButtonLink {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: var(--color-semantic-interactive-default);
  font-weight: 600;
  text-decoration: none;

  &:hover {
    color: var(--color-semantic-interactive-hover);
  }

  &:focus-visible {
    outline: 0.25rem solid var(--color-semantic-focus-default);
    outline-offset: 0.25rem;
  }
}

.ButtonLinkText {
  color: var(--color-semantic-text-default);

  &:focus {
    color: var(--color-semantic-text-default);
  }

  &:hover {
    color: var(--color-semantic-text-default);
  }
}
