@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.SummaryGroupsWrapper {
  &:not(:last-child) {
    border-bottom: 1px dashed var(--color-semantic-border-default);
  }
}

.highlighted {
  background-color: var(--color-semantic-surface-warning-subdued);
}

.ClearCartModalInner {
  max-width: 542px;
  text-align: center;
}

.ClearCartModalFooter {
  width: 196px;
}

.BackButton {
  margin-right: var(--spacing-4);
  order: 2;

  @include media-query.breakpoint(md) {
    order: 1;
  }
}

.RemoveButton {
  order: 1;
  margin-bottom: var(--spacing-3);

  @include media-query.breakpoint(md) {
    order: 2;
    margin-bottom: 0px;
  }
}

.AddCouponCodeButton {
  width: 100%;
  background-color: var(--color-semantic-surface-subdued);
  color: var(--color-semantic-text-default);

  border: none;

  &:hover {
    cursor: pointer;
    background-color: var(--color-semantic-surface-hover);
  }

  &:active {
    background-color: var(--color-semantic-surface-neutral-accent);
  }
}

.ValidateCouponContainer {
  background-color: var(--color-semantic-surface-subdued);
}

.FormContainer {
  position: relative;
}

.ValidateCouponCodeButton {
  position: absolute;
  min-width: 55px;
  top: 4px;
  right: 4px;
}

.DeliveryExemptTooltip {
  margin-left: auto;
  padding-right: 10px;
}

.ExtraInfoBanner {
  border-radius: 8px;
}

.PromotedProductsColumn {
  grid-template-columns: 1fr;

  @include media-query.breakpoint(lg) {
    grid-template-columns: 1fr 1fr;
  }
}
