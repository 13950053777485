@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss' as mq;

.classifierWrapper {
  position: relative;
  background-color: var(--color-semantic-surface-product-image-background);
  border-radius: var(--border-radius-full);
  overflow: hidden;
}

.ProductTile {
  height: 20px;
  width: 20px;
  padding: calc(var(--spacing-1) / 2);
}

.ProductRow {
  height: 16px;
  width: 16px;
  padding: calc(var(--spacing-1) / 2);
}

.ProductDetail {
  width: 32px;
  height: 32px;
  padding: var(--spacing-1);

  @include mq.breakpoint(md) {
    width: 48px;
    height: 48px;
    padding: var(--spacing-2);
  }
}

.image {
  max-width: 100%;
  max-height: 100%;
}
