@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

$productRowHeight: 127px;
$productRowLgHeight: 92px;
$productRowXlHeight: 72px;

.ProductRowContainer {
  position: relative;
  box-shadow: 0px 2px 12px rgba(234, 231, 227, 0.7);
  transition:
    background-color 0.2s ease-in,
    border-color 0.2s ease-in;
  height: $productRowHeight;
  align-content: center;
  isolation: isolate;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    'picture info info info basket'
    'picture info info info basket'
    'picture buttons buttons price price'
    'picture buttons buttons price price';

  &__default {
    background-color: var(--color-semantic-surface-default);
  }

  &__unavailable {
    background-color: var(--color-semantic-surface-critical-default);
  }

  &__newEntry {
    background-color: var(--color-semantic-surface-success-default);
  }

  @include media-query.breakpoint(lg) {
    height: $productRowLgHeight;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'picture info info info info buttons buttons buttons basket'
      'picture info info info info price price price basket';
  }

  @include media-query.breakpoint(xl) {
    height: $productRowXlHeight;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-template-areas: 'picture info info info info buttons price price basket';
  }
}

.ProductRowPictureContainer {
  grid-area: picture;

  .PictureWrapper {
    width: 43px;
  }
}

.ProductRowInfoContainer {
  grid-area: info;

  .InfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .ProductInfo {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.ProductRowCartButtonsContainer {
  grid-area: buttons;
}

.ProductRowPriceContainer {
  grid-area: price;

  &.PushViewToEnd {
    grid-area: basket;
  }
}

.ProductRowBasketContainer {
  grid-area: basket;
  width: 100%;

  .BasketButtonLink {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    @include media-query.breakpoint(lg) {
      align-items: center;
    }

    @include media-query.breakpoint(xl) {
      align-items: center;
    }

    .BasketItemRemovalWrapper {
      width: 100%;
      height: 100%;

      .BasketIconHoverWrapper {
        width: 25px;
        height: 25px;
        border-radius: 50%;

        &:hover {
          background-color: var(--color-semantic-surface-subdued);
        }
      }

      .BasketIconWrapper {
        height: 16px;
        width: 16px;
      }

      .BasketIcon {
        fill: var(--color-semantic-icon-on-button-tertiary);
      }
    }
  }
}

.animateIn {
  animation-name: fadeIn;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.zIndex1 {
  position: relative;
  z-index: 1;
}
