@use '../../../styles/utils/reset-button-styles.scss';
@use '~@kolonialno/kolibri-web/styles/utils/focus-ring';

.UnstyledButton {
  @include reset-button-styles.reset-button-styles;

  &:focus,
  &:focus-visible {
    @include focus-ring.focus-ring-styles;
  }
}