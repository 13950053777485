@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';
@use '~@kolonialno/kolibri-web/styles/utils/focus-ring';
@use 'mini-cart-vars.scss';

.MiniCart {
  position: fixed;
  overflow-y: scroll;
  isolation: isolate;

  top: 0;
  right: 0;

  height: 100vh;
  width: mini-cart-vars.$mini-cart-width;

  background-color: var(--color-semantic-background-subdued);
  color: var(--color-semantic-text-default);
  box-shadow: -2px 0px 10px 1px rgba(216, 210, 203, 0.2);
  z-index: 3;

  @media (prefers-reduced-motion: no-preference) {
    transition: all mini-cart-vars.$transition-duration ease-in-out;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow: -2px 0px 10px 1px #20202044;
  }

  .MiniCartItemIndicator {
    height: 45px;

    justify-content: space-between;
  }

  .MiniCartCloseButton {
    height: 45px;
    border-bottom: 1px solid var(--color-semantic-divider-subdued);
    color: var(--color-semantic-text-default);
  }

  .MiniCartContent {
    flex: 1;

    .MiniCartInnerContentWrapper {
      height: 100%;
      justify-content: space-between;
    }

    .MiniCartGroupWrapper .MiniCartProductWrapper:not(:last-child) {
      border-bottom: 1px solid var(--color-semantic-divider-subdued);
    }

    .TipTitle {
      font-size: 18px;
    }
  }

  .MiniCartButtonWrapper {
    position: sticky;
    bottom: var(--spacing-4);
    width: 100%;

    a {
      box-shadow: 0px 0px 10px 1px var(--color-semantic-border-hover);
    }
  }

  .MiniCartGroupingTitle {
    text-decoration: none;
    color: var(--color-semantic-text-default);

    background-color: var(--color-semantic-background-default);
  }
}

.HideMiniCart {
  right: -(mini-cart-vars.$mini-cart-width);
  visibility: hidden;
}

.MiniCartOverlay {
  position: fixed;

  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  cursor: pointer;

  background: #1f1e1c;
  opacity: 0.7;

  z-index: 3;

  @media (prefers-reduced-motion: no-preference) {
    animation: fade mini-cart-vars.$transition-duration;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}
