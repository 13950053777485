.RelativeWrapper {
  position: relative;
}
.AbsoluteWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  opacity: 0;
  transform: translateY(-8px);
  transition: transform 200ms, opacity 200ms;
  z-index: 100;
}

.Visible {
  opacity: 1;
  transform: translateY(8px);
}

.ScrollableContainer {
  overflow-y: scroll;
  max-height: 80vh;
}

.PopoverBox {
  box-shadow: 0px 4px 26px 0px rgba(75, 72, 68, 0.16);
  border: 1px solid;
  border-color: var(--color-semantic-border-default);
  border-radius: 8px;
}
