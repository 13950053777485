@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.listColumns {
  display: grid;
  column-gap: var(--spacing-4);

  @include media-query.breakpoint(md) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

.listItem {
  height: 68px;
  width: 100%;
  border-bottom: 1px solid var(--color-semantic-divider-subdued);
  text-decoration: none;
}

@include media-query.breakpoint(md) {
  .listItem:nth-child(1) {
    border-top: 1px solid var(--color-semantic-divider-subdued);
  }
}

@include media-query.breakpoint(lg) {
  .listItem:nth-child(1),
  .listItem:nth-child(2) {
    border-top: 1px solid var(--color-semantic-divider-subdued);
  }
}
