@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.LinkListColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  vertical-align: top;
  gap: 8px;
}

.cellContainer {
  position: relative;
  overflow: hidden;
  height: 80px;
  @include media-query.breakpoint(lg) {
    max-width: 200px;
  }
}

.cellFade {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(15, 6, 0, 0) 0%,
    rgba(15, 6, 0, 0.544) 70.31%
  );

  :hover {
    background: linear-gradient(
      180deg,
      rgba(15, 6, 0, 0.15) 0%,
      rgba(15, 6, 0, 0.744) 70.31%
    );
  }
}

.cellText {
  height: 100%;
  color: white;
  text-align: center;

  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

.promotionLabel {
  position: absolute;
  top: var(--spacing-2);
  right: 0;
  z-index: 3;
}

.image {
  position: absolute;
  z-index: 0;
}

