.imageGrid {
  display: grid;

  align-content: center;
  justify-content: center;

  background-color: var(--color-semantic-surface-product-image-background);

  > img {
    max-height: 100%;
    margin: auto;
  }
}

.row {
  width: 72px;
  height: 72px;
  grid-template-columns: repeat(2, 25px);
  grid-template-rows: repeat(2, 25px);
  gap: var(--spacing-1);
}

.tile {
  width: 100%;
  height: 200px;
  grid-template-columns: repeat(2, minmax(0px, 70px));
  grid-template-rows: repeat(2, minmax(0px, 70px));
  gap: var(--spacing-4);
}

.listTiles {
  grid-template-columns: repeat(auto-fill, minmax(182px, 1fr));
  gap: var(--spacing-6);
}