.LoadingScreenWrapper {
  height: 400px;
  width: 100%;
}

.LoadingScreen {
  position: absolute;
  height: 400px;
  width: 100%;
  z-index: 2;
  left: 0;

}

.overlay {
  background-color: var(--color-semantic-background-default);
}

.LoadingScreenBlock {
  top: var(--spacing-9);

  display: flex;

  height: 240px;
  width: 240px;

  background-color: var(--color-semantic-background-subdued);

  box-shadow: 0px 2px 12px var(--color-semantic-surface-subdued);
  border-radius: var(--border-radius-small);
}
