.ProductTile {
  isolation: isolate;
  height: 100%;

  & .Footer {
    margin-top: auto;
  }
}

.ProductTileLink {
  &:focus-visible {
    outline-offset: -0.25rem;
  }
}

.promotionBubbleAbs {
  position: absolute;
  top: 30%;
  left: 0;
  z-index: 3;
}

.promotionLabelAbs {
  position: absolute;
  top: var(--spacing-2);
  right: 0;
  z-index: 3;
}

.imageWrapper {
  width: 144px;
  position: relative;
}

.productImage {
  background-color: var(--color-semantic-surface-product-image-background);
}

.productClassifiersInTile {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
}

.classifierImage {
  max-width: 100%;
  max-height: 100%;
}

.relativeBox {
  background-color: var(--color-semantic-surface-product-image-background);
  position: relative;
}

.soldOutBadge {
  position: absolute;
  bottom: -8px;
  left: 0px;
  right: 0px;
}

.hyphens {
  hyphens: auto;
  word-break: normal;
  overflow-wrap: anywhere;
}

.bodyText {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.renderAction {
  z-index: 1;
}

.categoriesWrapper {
  z-index: 1;
}

.categoryLink {
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
}