@use '~@kolonialno/kolibri-web/styles/utils/focus-ring';

.WithFocus {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    @include focus-ring.focus-ring-styles;
    outline-offset: -4px;
  }

  &[data-focus-visible-added] {
    @include focus-ring.focus-ring-styles;
    outline-offset: -4px;
  }
}