@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.SearchBar {
  transition: border-color 250ms;

  &__large {
    height: 48px;
  }

  &__medium {
    height: 40px;

    .input {
      @include media-query.breakpoint(lg) {
        font-size: var(--text-style-body-s-font-size);
        line-height: var(--text-style-body-s-line-height);
        font-family: var(--text-style-body-s-font-family);
        font-weight: var(--text-style-body-s-font-weight);
      }
    }
  }
}

.SearchBarInFocus {
  border-color: var(--color-semantic-focus-default);
  box-shadow: 0 0 0 1px var(--color-semantic-focus-default);

  .submit.hideSearchButtonOnFocusOnSmallScreens {
    display: none;

    @include media-query.breakpoint(md) {
      display: flex;
    }
  }
}

.input {
  flex: 1;
  box-shadow: none;
  outline: none;
  border: none;
  padding: 0;
  color: var(--color-semantic-text-default);
  background: inherit;
  height: 100%;
  width: inherit;

  &::placeholder {
    color: var(--color-semantic-text-disabled);
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }

  /* clears the ‘X’ from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.searchIconButton {
  svg {
    fill: var(--color-semantic-icon-disabled);
  }
}
