@use 'sass:color';
@use '../../../styles/utils/media.scss';

.defaultPadding {
  padding-top: var(--spacing-2);
  margin-top: -8px;
}

.suppressPadding {
  padding-top: 0px;
  margin-top: 0px;
}

@include media.uniqueMedia('hideArrows') {
  display: none;
}

@include media.uniqueMedia('scrollableContainer') {
  overflow: auto;
  display: flex;

  white-space: nowrap;
  scroll-snap-type: x mandatory;

  // hide the search bar
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  &::-webkit-scrollbar {
    /* Chrome */
    display: none;
  }
}
