.listCard {
  width: 100%;
}

.AppLinkButton {
  text-decoration: none;
}

.createListButton {
  width: 72px;
  height: 72px;
}

.fullWidth {
  width: 100%;
}

.flexEnd {
  margin-left: auto;
}

.modalBody {
  padding: 0;
}

.productListEntry {
  cursor: pointer;

  &:hover {
    background-color: var(--color-semantic-surface-hover);
  }
}

.StickyCloseButton {
  background-color: var(--color-semantic-background-default);
  box-shadow: 0px 20px 20px 50px var(--color-semantic-background-default);
}

