.hyphens {
  word-break: normal;
  overflow-wrap: anywhere;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProductCard {
  height: 100%;
  isolation: isolate;
}

.ProductRow {
  isolation: isolate;
  container-type: inline-size;

  .priceAndDiscount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-0);
  }

  .ProductRowAction {
    align-self: end;
  }

  @container (min-width: 340px) {
    .priceAndDiscount {
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-1);
    }

    .ProductRowAction {
      align-self: center;
    }
  }
}

.ProductTileImageBox {
  background-color: var(--color-semantic-surface-product-image-background);
}

.ProductClassifiersTile {
  z-index: 2;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.ProductClassifiersRow {
  z-index: 2;
  position: absolute;
  bottom: calc(var(--spacing-1) * -1);
  left: var(--spacing-1);
}

.PromotionalLabels {
  z-index: 2;
  top: 2px;
  left: 2px;
}

.ProductTileContentBox {
  flex-grow: 1;
}

.AvailabilityContainer {
  min-height: var(--spacing-5);
}

.ProductRowAction {
  position: absolute;
  right: var(--spacing-2);
}

.BubbleWrapper {
  top: 2px;
  right: 2px;
  z-index: 2;
}

.DiscountOrPromotionPill {
  position: absolute;
  top: calc(var(--spacing-2) * -1);
  left: calc(var(--spacing-1) * -1);
  z-index: 2;
}

.ProductNameExtraTile {
  line-clamp: 3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}