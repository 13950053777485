.w-100 {
  width: 100px;
}

.bone {
  isolation: isolate;
}

.text-small {
  height: 12px;
}

.text-medium {
  height: 16px;
}

.text-large {
  height: 24px;
}

.text-x-large {
  height: 32px;
}

.product-image-small {
  height: 64px;
  width: 64px;
}

.product-image-medium {
  height: 112px;
  width: 112px;
}

.stepper-buttons {
  height: 32px;
  width: 32px;
}

.jumbo-button {
  height: 68px;
}

.action-button {
  height: 48px;
}

.radio-check {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.slot-cell {
  width: 100%;
  height: 50px;
}

.glossy-thingy {
  position: absolute;
  inset: 0;
  background: linear-gradient(110deg, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0.3) 18%, rgba(255, 255, 255, 0) 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  animation-duration: 1.5s;

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(110deg, rgba(0, 0, 0, 0) 8%, rgba(0, 0, 0, 0.4) 18%, rgba(0, 0, 0, 0) 33%);
    background-size: 200% 100%;
  }

  @media (prefers-reduced-motion) {
    background: none;
    animation: none
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}