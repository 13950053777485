
.OrderFeeProgressTracker {
    --track-color: var(--color-semantic-surface-neutral-default);
    --progress-color: var(--color-semantic-surface-success-accent);
  
    --progress-percent: 0%;
    position: relative;
    width: 100%;
    height: var(--spacing-1);
  }
  
  .Track {
    position: absolute;
    inset: 0;
    border-radius: var(--spacing-3);
    background-color: var(--track-color);
  }
  
  .CheckPoint {
    background-color: var(--track-color);
    transform: translateY(-25%);
    position: absolute;
    left: 98%;
    border-radius: 50%;
    width: var(--spacing-2);
    height: var(--spacing-2);
  
    &__isChecked {
      background-color: var(--progress-color);
    }
  }
  
  .CheckpointIcon {
    position: absolute;
    color: var(--color-semantic-icon-on-button-critical);
    width: var(--spacing-2);
    height: var(--spacing-2);
  }
  
  .Progress {
    position: absolute;
    border-radius: var(--spacing-3);
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--progress-percent);
    background-color: var(--progress-color);
  }
  
  