@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

$mega-menu-z-index: 4;

.MegaMenuOverlay {
  position: fixed;
  inset: 0;
  isolation: isolate;
  z-index: $mega-menu-z-index;

  height: 100vh;

  background-color: rgba(44, 44, 44, 0.2);
  @include media-query.breakpoint(md) {
    position: absolute;
    top: 45px;
    bottom: auto;
    left: 0;
    width: 100%;
  }

  @media (prefers-color-scheme: dark) {
    background-color: rgba(18, 18, 18, 0.75);
  }
}

.MegaMenuWrapper {
  height: 100%;
}

.MegaMenu {
  position: relative;
  isolation: isolate;
  z-index: $mega-menu-z-index + 1;
  height: 100vh;
  
  overscroll-behavior: contain;
  overflow-y: auto;

  background-color: var(--color-semantic-background-subdued);

  @include media-query.breakpoint(md) {  
    top: auto;
    bottom: auto;
    height: calc(100vh - 20vh);
  
    box-shadow: 0px 24px 24px rgba(53, 51, 47, 0.12);
    border-radius: 0px 0px 16px 16px;
  }
}

.MegaMenuContent {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.closeButton {
  z-index: 10;
  position: absolute;
  right: 24px;
  top: 24px;
}

.grid {
  width: 100%;
  position: relative;
  max-width: 1292px;
  display: grid;
  row-gap: var(--spacing-4);
  column-gap: var(--spacing-9);
  grid-template-columns: 100%;

  @include media-query.breakpoint(md) {
    grid-template-columns: 1fr 30px 1fr;
    row-gap: var(--spacing-7);
  }
  @include media-query.breakpoint(lg) {
    grid-template-columns: 1fr 30px 2fr;
    row-gap: var(--spacing-7);
  }
}

@include media-query.breakpoint(md) {
  .pinnedCategories {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .banners {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .categories {
    grid-column: 2 / 4;
    grid-row: 1 / 4;
  }
}
