@use '~@kolonialno/kolibri-web/styles/utils/responsive-classes-for-boolean-props.scss';
@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

@include responsive-classes-for-boolean-props.responsiveClassesForBooleanProps("sticky") {
  position: sticky;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}
