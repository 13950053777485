@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.breadcrumb {
  display: block;
}

.breadcrumbLast {
  display: none;
  @include media-query.breakpoint(md) {
    display: inline;
  }
}

.breadcrumbSlash {
  display: none;
  @include media-query.breakpoint(md) {
    display: inline;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
  }
}

.BreadcrumbContainer {
  .breadcrumb:not(:last-of-type) {
    display: none;
  }
  @include media-query.breakpoint(md) {
    flex-wrap: wrap;
    .breadcrumb:not(:last-of-type) {
      display: inline;
    }
  }
}

.Icon {
  @include media-query.breakpoint(md) {
    display: none;
  }
}
