@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.highlightTile {
  width: 100%;
  max-width: 524px;
}

.flexTile {
  max-width: 200px;
  width: 200px;

  @include media-query.breakpoint(null, sm) {
    min-width: 165px;
    width: 47%;
  }
}
