.Wrapper {
  position: relative;
}

.SuccessButton {
    background-color: var(--color-semantic-surface-spotlight-default);
    color: var(--color-semantic-text-on-spotlight);
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: scaleIn;
    animation-duration: 200ms;
    animation-timing-function: ease-out;
  }

  @keyframes scaleIn {
    0% {
        transform: scale(0);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
  }