@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

$picture-size: 54px;
$basket-size: 48px;
$basket-size-xl: 32px;

.ProductRowWrapper {
  border-bottom: 1px solid var(--color-semantic-border-default);
}

.ProductRowWrapper:last-child {
  border-bottom: none;
}

.ProductRowContainer {
  display: grid;
  position: relative;
  transition:
    background-color 0.2s ease-in,
    border-color 0.2s ease-in;
  align-content: center;
  isolation: isolate;
  grid-template-columns: $picture-size repeat(3, 1fr) $basket-size;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: var(--spacing-4);
  grid-template-areas:
    'picture info info info basket'
    'picture info info info basket'
    'picture buttons buttons price price'
    'picture buttons buttons price price';

  &__default {
    background-color: var(--color-semantic-surface-default);
  }

  &__unavailable {
    background-color: var(--color-semantic-surface-critical-default);
    box-shadow: none;
  }

  &__newEntry {
    background-color: var(--color-semantic-surface-success-default);
  }

  &.CompressedLayout {
    border-radius: 0;
    height: 112px;
  }

  &.ExpandedLayout {
    height: 127px;

    @include media-query.breakpoint(lg) {
      height: 92px;
      grid-template-columns: $picture-size repeat(7, 1fr) $basket-size;
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas:
        'picture info info info info buttons buttons buttons basket'
        'picture info info info info price price price basket';
    }

    @include media-query.breakpoint(xl) {
      height: 72px;
      grid-template-columns: $picture-size repeat(7, 1fr) $basket-size-xl;
      grid-template-rows: repeat(1, 1fr);
      grid-template-areas: 'picture info info info info buttons price price basket';
    }
  }

  &.Elevated {
    box-shadow: 0px 2px 12px var(--color-semantic-interactive-disabled);
  }

  &.Flat {
    box-shadow: none;
  }
}

.ProductRowPictureContainer {
  grid-area: picture;
  contain: content;
  isolation: isolate;
}

.ProductRowInfoContainer {
  grid-area: info;

  .InfoWrapper {
    width: 100%;

    .ProductInfoTextWrapper {
      max-width: 100%;
    }

    .AvailabilityPill {
      flex: 0 0 auto;
    }
  }

  .ProductInfoText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ProductRowCartButtonsContainer {
  grid-area: buttons;
}

.ProductRowPriceContainer {
  grid-area: price;

  &.PushViewToEnd {
    grid-area: basket;
    padding-right: var(--spacing-3);

    @include media-query.breakpoint(null, lg) {
      grid-area: buttons;
      justify-self: start;
    }
  }
}

.ProductRowBasketContainer {
  grid-area: basket;

  .BasketIconWrapper {
    width: 100%;
    height: 41px;

    @include media-query.breakpoint(lg) {
      height: 30px;
    }

    @include media-query.breakpoint(xl) {
      height: 36px;
    }

    .BasketIcon {
      width: 16px;
      height: 16px;

      @include media-query.breakpoint(lg) {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.PromotionsNode {
  position: absolute;
  top: var(--spacing-1);
  left: 0;
  z-index: 2;
}

.animateIn {
  animation-name: scaleIn;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
