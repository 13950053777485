.arrow-button {
  position: absolute;
  z-index: 1;
  height: 32px;
  width: 32px;
  background-color: var(--color-semantic-interactive-default);

  &:hover {
    background-color: var(--color-semantic-interactive-hover);
  }

  &:active {
    background-color: var(--color-semantic-interactive-pressed);
  }
}

.start {
  left: 0px;
  & :global(.k-icon) {
    margin-left: calc((var(--spacing-1) / 2) * -1);
  }

  &-outside {
    left: calc(var(--spacing-4) * -1);
    & :global(.k-icon) {
      margin-left: calc((var(--spacing-1) / 2) * -1);
    }
  }
}

.end {
  right: 0px;
  & :global(.k-icon) {
    margin-right: calc((var(--spacing-1) / 2) * -1);
  }

  &-outside {
    right: calc(var(--spacing-4) * -1);
    & :global(.k-icon) {
      margin-right: calc((var(--spacing-1) / 2) * -1);
    }
  }
}