.undiscountedPrice {
  text-decoration: line-through;
}

.productClassifiersInListItem {
  position: absolute;
  display: flex;
  width: 64px;
  flex-wrap: wrap;
  z-index: 2;
  bottom: var(--spacing-2);
}

.relativeBox {
  position: relative;
  isolation: isolate;
  width: 100%;
}

.priceAndButtons {
  width: 100%;
}

.TextsWrapper {
  width: 100%;
}

.promotionLabel {
  position: absolute;
  top: var(--spacing-6);
  right: 0;
  z-index: 3;
}
