@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.SearchBarContainer {

  & .BackButton {
    display: none;
  }

  &.isInFocus {
    position: fixed;
    inset: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--color-semantic-background-subdued);

    & .backButtonAndInputContainer {
      padding: var(--spacing-2);
    }

    & .BackButton {
      display: block;
    }
  }

  @include media-query.breakpoint(md) {
    &.isInFocus {
      background-color: unset;
      position: static;
      padding: var(--spacing-0);
      height: auto;
      display: block;

      & .backButtonAndInputContainer {
        padding: var(--spacing-0);
      }

      & .BackButton {
        display: none;
      }
    }
  }

  @include media-query.breakpoint(lg) {
    flex-grow: 1;
    min-width: 335px;
    max-width: 565px;
  }
}

.inline {
  display: none;

  &.inFocus {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 100%;
  }
}

.SectionTitleWrapper {
  color: var(--color-semantic-text-default);

  background-color: var(--color-semantic-background-default);
  text-decoration: none;
}

.FooterLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-3);

  background-color: var(--color-semantic-surface-subdued);
  border-top-width: 1px;
  border-top-color: var(--color-semantic-border-default);
  border-top-style: solid;

  &:focus {
    background-color: var(--color-semantic-surface-hover);
    outline: none;
  }

  &:hover {
    background-color: var(--color-semantic-surface-hover);
    outline: none;
  }
}

.ProductRow {
  &:hover {
    background-color: var(--color-semantic-background-subdued);
  }
}

.SuggestionsWrapper {
  position: relative;
  margin-bottom: -var(--spacing-2);
}

.scrollableContainer {
  overflow-y: scroll;
  flex-grow: 1;
}

.displayContents {
  display: contents;
}
