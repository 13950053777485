@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.imageBox {
  @include media-query.breakpoint(md) {
    height: 100%;
  }
}

.classifierWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
}

.discountBubble {
  position: absolute;
  top: var(--spacing-3);
  left: var(--spacing-3);

  @include media-query.breakpoint(md) {
    top: var(--spacing-5);
    left: var(--spacing-5);
  }
}