.ReadMore {
  overflow: hidden;

  &__shouldExpand {
    margin-bottom: var(--spacing-2);
    mask-image: linear-gradient(to top, transparent, white 100px);
    transition: max-height 0.2s ease-in-out;
  }

  &__extended {
    mask-image: linear-gradient(to top, white, white 100px);
    transition: max-height 0.2s ease-in-out;
  }
}
